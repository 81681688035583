import React from 'react';
import Navbar from '../Navbar';
import './styles.scss';

const Header = () => {

    return (
        <>
            <Navbar />
            <div className="Header" title="Sesja stylizowana - realizacja Czary Marry wedding planner"></div>
        </>
    )
}

export default Header